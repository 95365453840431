#aboutus_bg .aboutus_con{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}
#aboutus_bg {
    padding-top: 60px;
    padding-bottom: 80px;
    background: #FAFAFA;
}
#aboutus_bg .aboutus_con .abotus_left {
    float: left;
    width: 520px;
    word-break: break-word;
}
#aboutus_bg .aboutus_con .aboutus_right {
    float: right;
    width: 500px;
}
#aboutus_bg .aboutus_con .abotus_left h2 {
    font-family: "impact";
    font-size: 42px;
    color: #545454;
}
#aboutus_bg .aboutus_con .abotus_left h2::after {

    content: ' ';
    display: block;
    width: 110px;
    margin-top: 10px;
    margin-bottom: 28px;

}
#aboutus_bg .aboutus_con .abotus_left p {
    font-size: 18px;
    line-height: 42px;
    color: #646464;
}
#aboutus_bg .aboutus_con .abotus_left .p {
    font-size: 24px;
    margin-bottom: 32px;
    line-height: 30px;
}
#aboutus_bg .aboutus_con .aboutus_right {
    float: right;
    width: 600px;
    padding: 18px;
    background: #DCDCDC;
    border-radius: 16px;
}

#index-a {
    background: url("/images./indexa.png") no-repeat center center;
    background-size: cover;
}
#index-a .title h2 {
    padding: 80px 0 10px;
    text-align: center;
    font-size: 42px;
    font-family: "impact";
}
#index-a .title p{
    margin-bottom: 100px;
}
#index-a .title p img {
    margin: 0 80px;
}
#index-a .title p::before {
    content: " ";
    display: block;
    width: 100%;
    border-bottom: 1px solid #7DA097;
}
#index-a .title p::after {
    content: " ";
    display: block;
    width: 100%;
    border-bottom: 1px solid #7DA097;
}
#index-a .index-a-body {
    position: relative;
    height: 700px;
}
#index-a .index-a-body .list {
    height: 580px;
}
#index-a .index-a-body .list::after {
    content: " ";
    display: block;
    border-bottom: 4px solid #027E5A;
    position: absolute;
    top: 250px;
    left: 0;
    right: 0;
}
#index-a .index-a-body .list .item {
    float: left;
    width: 280px;
    margin: 0 10px;
}
#index-a .index-a-body .list .item img {
    width: 100%;
    display: block;
}
#index-a .index-a-body .list .item h3 {
    padding: 20px 20px;
    height: 110px;
}
#index-a .index-a-body .list .item h3 p{
    font-size: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
#index-a .index-a-body .list .item h2 {
    text-align: center;
    font-size: 32px;
}
#index-a .index-a-body .list .item.linkb img {
    margin-top: 134px;
}
#index-a .index-a-body .list .item.linka img {
    margin-bottom: 170px;
}
#index-a .index-a-body .list .item a{
    text-decoration: none;
}
#index-a .index-a-body .dian li {
    position: absolute;
    height: 98px;
    width: 52px;
}
#index-a .index-a-body .dian li:nth-child(1) {
    background: url("/images/diana.png") no-repeat;
    left: 128px;
    top: 180px;
}
#index-a .index-a-body .dian li:nth-child(2) {
    background: url("/images/diana.png") no-repeat;
    left: 723px;
    top: 180px;
}
#index-a .index-a-body .dian li:nth-child(3){
    background: url("/images/dianb.png") no-repeat;
    left: 426px;
    top: 225px;
}
#index-a .index-a-body .dian li:nth-child(4){
    background: url("/images/dianb.png") no-repeat;
    left: 1030px;
    top: 225px;
}
#index-a .index-a-body .more a {
    display: inline-block;
    padding: 6px 20px;
    background: #BFBFBF;
    font-size: 18px;
    color: #027E5A;
    border-radius: 83px;
    margin-top: 16px;
}
#index-a .index-a-body .more{
    text-align: center
}
#index-a .index-a-body .list .item a:hover h3 {
    color: #027E5A;
}
#index-a .index-a-body .more a:hover {
    background: #027E5A;
    color: #fff;
}

#index-b {
    background: url('/images/map.png') no-repeat;
    background-size: cover;
}
#index-b .title {
    position: relative;
}
#index-b .title h2 {
    text-align: center;
    font-size: 42px;
    padding: 80px 0 60px;
    font-family: "impact";
}
#index-b .title a {
    position: absolute;
    bottom: 45px;
    right: 20px;
}
#index-b .title a::before {
    background: #027E5A;
    content: " ";
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -15px;
    right: -16px;
    z-index: 1;
}
#index-b .title a p {
    z-index: 999;
    position: relative;
    font-size: 18px;
    font-family: "impact";
}
#index-b .index-b-body .left {
    float: left;
    width: 580px;
    position: relative;
    
}
#index-b .index-b-body .right {
    float: right;
    width: 580px;
}
#index-b .index-b-body .right img {
    width: 100%;
}
#index-b .index-b-body {
    padding: 40px 0 70px;
}

#index-b .index-b-body .left::after {
    content: " ";
    display: block;
    background: #DD7203;
    width: 10px;
    position: absolute;
    top: 0;
    left: 8px;
    bottom: 0;
}
#index-b .index-b-body .left ul li {
    display: -moz-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -moz-align-items: center;
    -o-align-items: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 20px 0;
}
#index-b .index-b-body .left ul li span {
    background: #DD7203;
    height: 26px;
    width: 26px;
    text-align: center;
    display: inline-block;
    line-height: 26px;
    border-radius: 50%;
    flex-shrink: 0;
    z-index: 111;
    color: #fff;
}
#index-b .index-b-body .left ul li p {
    padding-left: 10px;
    font-size: 15px;
    line-height: 20px;
    color: #DD7203;
}
#index-c {
    background: url("/images/5O1A5127.png") no-repeat center center;
    background-size: cover;
    padding-bottom: 80px;
}
#index-c .title h2 {
    text-align: center;
    font-size: 42px;
    padding: 60px 0;
    color: #fff;
    font-family: "impact";
}

#index-c .index-c-body .item {
    float: left;
    width: calc(100% / 5 - 10px);
    margin-right: 12px;
    background: #FFF;
}
#index-c .index-c-body .item:nth-child(5) {
    margin-right: 0;
}
#index-c .index-c-body .item img {
    width: 100%;
    display: block;
}
#index-c .index-c-body {
    padding: 27px 27px;
    background: rgba(255,255,255,0.5);
}
#index-c .index-c-body .item .item-body {
    padding: 12px 8px;
}
#index-c .index-c-body .item .item-body h2 {
    background: #007948;
    text-align: center;
    color: #FFF;
    padding: 8px 6px;
    font-size: 20px;
    border-radius: 40px;
    margin-bottom: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#index-c .index-c-body .item .item-body h3 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#index-c .index-c-body .item .item-body div {
    height: 110px;
}
#index-c .index-c-body .item .item-body p {
    font-size: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
}
#index-c .index-c-body .item .item-body a {
    display: block;
    text-align: center;
    font-size: 16px;
    margin-bottom: 6px;
}
#index-c .index-c-body .item .item-body a:hover {
    color: #007948;
}
#index-c .index-c-body .more a {
    display: inline-block;
    margin: 40px 0 10px;
    height: 44px;
    width: 44px;
    background: url("/images/morejia.png") no-repeat;
    text-indent: 99px;
    overflow: hidden;
    line-height: 50px;
}
#index-c .index-c-body .more {
    text-align: center;
}
#index-d {
    background: #EEE;
}
#index-d .title {
    text-align: center;
}
#index-d .title h2 {
    padding: 60px 0 10px;
    font-size: 42px;
    font-family: "impact";
}
#index-d .title p::after {
    content: " ";
    display: block;
    width: 100%;
    border-bottom: 1px solid #0C754A;
}
#index-d .title p::before {
    content: " ";
    display: block;
    width: 100%;
    border-bottom: 1px solid #0C754A;
}
#index-d .title p span {
    flex-shrink: 0;
    margin: 0px 40px;
    font-size: 22px;
    font-family: "impact";
    color: #0C754A;
}
#index-d .index-a-body .list {
    margin-top: 60px;
}
#index-d .index-a-body .list .item {
    float: left;
    width: calc(100% / 2 - 12px);
    margin-right: 24px;
    margin-bottom: 32px;
}
#index-d .index-a-body .list .item:nth-child(2n) {
    margin-right: 0;
}
#index-d .index-a-body .list .item .img {
    width: 296px;
}
#index-d .index-a-body .list .item .img img {
    display: block;
    width: 100%;
    object-fit: cover;
}
#index-d .index-a-body .list .item .item-body {
    width: 296px;
    background: #fff;
}
#index-d .index-a-body .list .item .item-body h2 {
    margin-top: 20px;
    font-size: 24px;
    padding-left: 20px;
    background: #0C754A;
    display: inline-block;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 180px;
    min-width: 170px;
    position: relative;
    color: #fff;
}
#index-d .index-a-body .list .item .item-body h2 span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    min-width: 170px;
    display: block;
}
#index-d .index-a-body .list .item .item-body h2::after {
    content: " ";
    display: block;
    width: 0px;
    height: 0px;
    border-left: 0 solid transparent;
    border-right: 40px solid transparent;
    border-top: 44px solid #0C754A;
    position: absolute;
    top: 0;
    right: -40px;
}
#index-d .index-a-body .list .item .item-body .p {
    padding: 16px 24px 10px;
    height: 116px;
}
#index-d .index-a-body .list .item .item-body .p p {
    font-size: 15px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    color: #5F5F5F;
}
#index-d .index-a-body .list .item .item-body a {
    text-align: center;
    display: block;
    font-size: 18px;
    color: #007948;
}
#index-d .index-a-body .more {
    text-align: center;
}
#index-d .index-a-body .more a {
    display: inline-block;
    margin: 40px auto 70px;
    height: 44px;
    width: 44px;
    background: url("/images/morejia.png") no-repeat;
}
#index-e {
    background: #78B89E;
    padding-bottom: 50px;
}
#index-e .title h2 span {
    flex-shrink: 0;
    margin: 0 60px;
    font-size: 42px;
    font-family: "impact";
}
#index-e .title h2::after {
    content: " ";
    display: block;
    width: 100%;
    border-bottom: 1px solid #FFF;
}
#index-e .title h2::before {
    content: " ";
    display: block;
    width: 100%;
    border-bottom: 1px solid #FFF;
}
#index-e .title h2 {
    padding: 70px 0 60px;
    color: #fff;
}
#index-e .wrapper .p {
    text-align: center;
    padding: 0 180px;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
}
#index-e .wrapper .list {
    margin-top: 90px;
}
#index-e .wrapper .list .item {
    width: calc(100% / 6 - 10px);
    float: left;
    margin-right: 12px;
    margin-bottom: 12px;
}
#index-e .wrapper .list .item:nth-child(6n) {
    margin-right: 0;
}
#index-e .wrapper .list .item img {
    width: 100%;
    display: block;
}


#index-f {
    background: #FFF;
    padding-bottom: 50px;
}
#index-f .title h2 span {
    flex-shrink: 0;
    margin: 0 60px;
    font-size: 42px;
    font-family: "impact";
}
#index-f .title h2::after {
    content: " ";
    display: block;
    width: 100%;
    border-bottom: 1px solid #000000;
}
#index-f .title h2::before {
    content: " ";
    display: block;
    width: 100%;
    border-bottom: 1px solid #000000;
}
#index-f .title h2 {
    padding: 100px 0 60px;
    color: #000000;
}
#index-f .wrapper .p {
    text-align: center;
    padding: 0 180px;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
#index-f .wrapper .list {
    margin-top: 80px;
}
#index-f .wrapper .list .item {
    float: left;
    width: calc(100% / 4 - 10px);
    margin-right: 13px;
    margin-bottom: 25px;
}
#index-f .wrapper .list .item:nth-child(4n) {
    margin-right: 0;
}
#index-f .wrapper .list .item .img img {
    width: 100%;
    display: block;
}
#index-f .wrapper .list .item h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    padding: 0 24px;
    margin-top: 6px;
    background: url("/images/biaodian.png") no-repeat 4px center;
}

#index-g {
    background: url('/images/indexg.png') no-repeat center center;
    background-size: cover;
    padding: 100px 0;
}
#index-g .title {
    text-align: center;
    width: 252px;
}
#index-g .title h2 {
    color: #fff;
    font-size: 49px;
    transform: rotate(90deg) translateX(260px) translateY(120px);
    width: 500px;
}
#index-g .index-g-body {
    width: 984px;
    margin-left: 18%;
    margin-bottom: 40px;
}
#index-g .index-g-body .p {
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    margin-top: 40px;
    margin-bottom: 100px;
}
#index-g .index-g-body .list .item {
    float: left;
    width: calc(100% / 5 - 10px);
    margin-right: 12px;
}
#index-g .index-g-body .list .item:nth-child(5n) {
    margin-right: 0;
}
#index-g .index-g-body .list .item img {
    width: 100%;
    display: block;
}
.index-j {
    background: #57A686;
    padding: 50px 0;
}
.index-j .content {
    font-size: 20px;
    text-align: center;
    width: 1200px;
    margin: 0 auto;
    font-family: "impact";
    color: #fff;
    line-height: 38px;
}
#footer{
    margin-top: 0;
}
#index-g .index-g-body .list .item:hover img {
    box-shadow: 0 2px 10px -3px #FFF;
}
.img {
    overflow: hidden;
}
.img img {
    transition: all 0.5s;
}
.img:hover img {
    transform: scale(1.1);
}